import React, { useEffect, useState } from 'react';
import styles from './ProfileDeletion.module.scss';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import logger from 'utils/logger/logger';
import { LOGIN, URL_QUERY_PARAM_KEY, useURLSearchParams } from 'utils/routingUtils';
import { logout } from 'services/auth.service';
import { deleteUser } from 'services/user.service';
import { RESET } from 'components/app/store';
import { Headline, Paragraph } from 'components/common';
import AuthPageWrap from '../../common/authPageWrap';

/**
 * Handles a link of type http://localhost:3000/#/delete-profile?key=4482371191437431
 * User can open this component when he is logged in or not. Both cases are handled.
 */
export default function ProfileDeletion(): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const key = useURLSearchParams().get(URL_QUERY_PARAM_KEY);

  const [deleted, setDeleted] = useState(false);
  const [deleting, setDeleting] = useState(true);

  useEffect(() => {
    if (key && deleting && !deleted) {
      logout().finally(() => {
        dispatch(RESET);
        deleteUser(key)
          .then((result) => {
            setDeleted(result);
          })
          .finally(() => setDeleting(false));
      });
    }
  }, [deleted, deleting, dispatch, key]);

  if (!key) {
    logger.error('No key found in the URL. It is a wrong flow');
    return <Navigate to={LOGIN} />;
  }
  if (deleting) {
    return <></>;
  }

  // key was checked and the result can be evaluated now

  return (
    <AuthPageWrap
      linkText={t('PUBLIC.PROFILE_DELETION.RETURN_TO', { page: t('PUBLIC.NAVIGATION.LOG_IN') })}
      linkHref={LOGIN}
      title={t('PUBLIC.PROFILE_DELETION.TITLE')}
      withBottomLine={true}
    >
      <div className={styles.content}>
        <div className={styles.successMessage}>
          <Headline size="medium" className={styles.title}>
            {deleted ? t('PUBLIC.PROFILE_DELETION.DELETED') : t('PUBLIC.PROFILE_DELETION.NOT_DELETED')}
          </Headline>
          <Paragraph size="medium" className={styles.subtitle}>
            {deleted ? t('PUBLIC.PROFILE_DELETION.THANK_YOU') : t('PUBLIC.PROFILE_DELETION.ERROR')}
          </Paragraph>
        </div>
      </div>
    </AuthPageWrap>
  );
}
