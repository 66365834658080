import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import { baseErrorNotification } from 'utils/notificationUtils';
import ToasterInfo from 'components/common/toasterInfo';
import { AD_DNA_COLUMNS } from 'components/features/private/adsDna/adsDnaUtils';
import { Button, Checkbox } from 'components/common';
import cloneDeep from 'lodash/cloneDeep';
import { CloseOutlined } from '@ant-design/icons';

import styles from './CustomizeColumnsModal.module.scss';
import { black_0, black_100 } from 'utils/styleUtils';

interface Props {
  columns: AD_DNA_COLUMNS[];
  onHide: () => void;
  onDone: (value: AD_DNA_COLUMNS[]) => void;
}

export default function CustomizeColumnsModal({ columns, onHide, onDone }: Props): JSX.Element {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(columns);

  const sections = useMemo(
    () => [
      {
        section: t('PRIVATE.USER.ADS_DNA.MAIN'),
        items: [
          AD_DNA_COLUMNS.PGID,
          AD_DNA_COLUMNS.PGID_AUTHOR,
          AD_DNA_COLUMNS.AD_TITLE,
          AD_DNA_COLUMNS.PROJECT,
          AD_DNA_COLUMNS.PAYMENT_MODEL,
          AD_DNA_COLUMNS.CHANNEL,
        ],
      },
      {
        section: t('PRIVATE.USER.ADS_DNA.AD_CONCEPT'),
        items: [
          AD_DNA_COLUMNS.PAIN_POINTS,
          AD_DNA_COLUMNS.PRODUCT_FEATURES,
          AD_DNA_COLUMNS.BENEFITS,
          AD_DNA_COLUMNS.VALUE_PROPOSITIONS,
          AD_DNA_COLUMNS.PERSONAS,
          AD_DNA_COLUMNS.ASSET_FORMAT,
          AD_DNA_COLUMNS.ASSET_RATIO,
          AD_DNA_COLUMNS.LAYOUT,
          AD_DNA_COLUMNS.VIDEO_LENGTH,
          AD_DNA_COLUMNS.SLIDES_NUMBER,
        ],
      },
      {
        section: t('PRIVATE.USER.ADS_DNA.ROLES'),
        items: [
          AD_DNA_COLUMNS.ACCOUNT_MANAGER,
          AD_DNA_COLUMNS.CREATIVE_STRATEGIST,
          AD_DNA_COLUMNS.COPYWRITER,
          AD_DNA_COLUMNS.GRAPHIC_DESIGNER,
          AD_DNA_COLUMNS.MOTION_DESIGNER,
          AD_DNA_COLUMNS.ILLUSTRATOR,
          AD_DNA_COLUMNS.MODEL,
          AD_DNA_COLUMNS.PHOTOGRAPHER,
          AD_DNA_COLUMNS.VIDEOGRAPHER,
          AD_DNA_COLUMNS.CREATOR,
        ],
      },
      {
        section: t('PRIVATE.USER.ADS_DNA.METHODOLOGY'),
        items: [AD_DNA_COLUMNS.CTA, AD_DNA_COLUMNS.CTA_PLACEMENT],
      },
    ],
    [t],
  );

  const showErrorNotification = useCallback((msg: string) => {
    toast.error(<ToasterInfo type="error" description={msg} />, { ...baseErrorNotification });
  }, []);

  const onCheck = useCallback(
    (value: AD_DNA_COLUMNS) => {
      if (selected.includes(value)) {
        setSelected((prev) => prev.filter((i) => i !== value));
      } else {
        setSelected((prev) => {
          const newArray = cloneDeep(prev);
          newArray.push(value);
          return newArray;
        });
      }
    },
    [selected],
  );

  const handleSubmit = useCallback((): void => {
    if (!selected.length) {
      showErrorNotification(t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.ERROR'));
      return;
    }

    onDone(selected);
  }, [onDone, selected, showErrorNotification, t]);

  return (
    <Modal
      open={true}
      onCancel={onHide}
      className={styles.modal}
      footer={null}
      maskClosable={false}
      width={800}
      closeIcon={
        <CloseOutlined
          rev={undefined}
          style={{
            color: black_0,
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = black_100)}
          onMouseLeave={(e) => (e.currentTarget.style.color = black_0)}
        />
      }
    >
      <div className={styles.modalHeader}>
        <div className={styles.title}>{t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.TITLE')}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.sections}>
          {sections.map(({ section, items }) => (
            <div key={section} className={styles.section}>
              <div className={styles.sectionHeader}>{section}</div>
              {items.map((i) => (
                <Checkbox
                  key={i}
                  className={styles.sectionItem}
                  label={t(`PRIVATE.USER.ADS_DNA.${i}`)}
                  labelAfter={true}
                  name={i}
                  checked={selected.includes(i)}
                  onChange={() => onCheck(i)}
                />
              ))}
            </div>
          ))}
        </div>
        <div className={styles.buttons}>
          <Button buttonType="secondary" onClick={onHide}>
            {t('COMMON.CANCEL')}
          </Button>
          <Button buttonType="primary" type="button" onClick={handleSubmit}>
            {t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.CTA')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
