import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { login } from 'services/auth.service';
import { reloadPage } from 'utils/windowUtils';
import { baseErrorNotification } from 'utils/notificationUtils';
import { toast } from 'react-toastify';
import { emailMaxLength, passwordMaxLength, passwordMinLength } from 'utils/userUtils';
import { PASSWORD_RESET, SIGNUP, URL_QUERY_PARAM_EXPIRED_SESSION, useURLSearchParams } from 'utils/routingUtils';
import { Button, Input, Link, Loading, Paragraph } from 'components/common';
import { ReactComponent as OpenEyeIcon } from 'assets/img/openEye.svg';
import { ReactComponent as ClosedEyeIcon } from 'assets/img/closedEye.svg';
import AuthPageWrap from '../../common/authPageWrap';
import ToasterInfo from 'components/common/toasterInfo';

import styles from './Login.module.scss';
import { getContactUsMessage, useProductName } from 'utils/i18nUtils';

interface Values {
  email: string;
  password: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean): any | (() => any) => {
  return Yup.object().shape({
    email: validateOnSubmit
      ? Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(emailMaxLength, t('VALIDATION.EMAIL_MAX_LENGTH', { length: emailMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .email(t('VALIDATION.EMAIL_VALID'))
          .max(emailMaxLength, t('VALIDATION.EMAIL_MAX_LENGTH', { length: emailMaxLength }))
          .optional(),
    password: validateOnSubmit
      ? Yup.string()
          .min(passwordMinLength, t('VALIDATION.PASSWORD_MIN_LENGTH', { length: passwordMinLength }))
          .max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH', { length: passwordMaxLength }))
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .min(passwordMinLength, t('VALIDATION.PASSWORD_MIN_LENGTH', { length: passwordMinLength }))
          .max(passwordMaxLength, t('VALIDATION.PASSWORD_MAX_LENGTH', { length: passwordMaxLength }))
          .optional(),
  });
};

export interface LoginLocationState {
  email?: string;
  password?: string;
}

const Login = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const productName = useProductName();
  const expiredSession = useURLSearchParams().has(URL_QUERY_PARAM_EXPIRED_SESSION);

  const [submitTriggered, setSubmitTriggered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const showErrorNotification = useCallback(
    (title: string) => {
      const message = t(getContactUsMessage());
      toast.error(<ToasterInfo type="error" title={title} description={message} />, { ...baseErrorNotification });
    },
    [t],
  );

  const handleSubmit = useCallback(
    async (
      values: Values,
      setSubmitting: (isSubmitting: boolean) => void,
      setErrors: (arg: LoginLocationState) => void,
    ): Promise<void> => {
      setSubmitTriggered(true);
      setSubmitting(true);
      try {
        const result = await login(values.email, values.password, false);
        if (result) {
          // update the page and let user start working
          reloadPage();
        } else {
          showErrorNotification(t('PUBLIC.LOGIN.FAILED'));
          setSubmitting(false);
          setErrors({ password: t('PUBLIC.LOGIN.INCORRECT_PASSWORD') });
        }
      } catch {
        showErrorNotification(t('PUBLIC.LOGIN.FAILED'));
        setSubmitting(false);
      }
    },
    [showErrorNotification, t],
  );

  const handleSubmitForm = useCallback(
    (values: Values, { setSubmitting, setErrors }: FormikHelpers<Values>) =>
      handleSubmit(values, setSubmitting, setErrors),
    [handleSubmit],
  );

  return (
    <AuthPageWrap
      linkHref={SIGNUP}
      linkText={''}
      title={t('PUBLIC.LOGIN.WELCOME', { product: productName })}
      withBottomLine={false}
    >
      <div className={styles.container}>
        <Formik
          validationSchema={validationSchema(t, submitTriggered)}
          onSubmit={handleSubmitForm}
          initialValues={{
            email: (location?.state as LoginLocationState)?.email || '',
            password: (location?.state as LoginLocationState)?.password || '',
          }}
        >
          {({ handleChange, handleBlur, values, errors, isSubmitting }) => (
            <Form noValidate className={styles.contentWrapper}>
              <div className={styles.content}>
                {expiredSession && (
                  <>
                    <div className={styles.expiredSession}>{t('PUBLIC.LOGIN.EXPIRED_SESSION')}</div>
                    <br />
                  </>
                )}
                <Input
                  type="email"
                  name="email"
                  autoFocus={true}
                  tabIndex={1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email}
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small">{t('PUBLIC.LOGIN.EMAIL')}</Paragraph>
                    </div>
                  }
                />
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  autoComplete="true"
                  tabIndex={2}
                  placeholder={t('PUBLIC.LOGIN.PASSWORD_PLACEHOLDER', { length: passwordMinLength })}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={errors.password}
                  icon={
                    showPassword ? (
                      <OpenEyeIcon onClick={() => setShowPassword(false)} />
                    ) : (
                      <ClosedEyeIcon onClick={() => setShowPassword(true)} />
                    )
                  }
                  alignIcon="right"
                  label={
                    <div className={styles.inputLabelContainer}>
                      <Paragraph size="small">{t('PUBLIC.LOGIN.PASSWORD')}</Paragraph>

                      <Link href={PASSWORD_RESET} className={styles.inputLink}>
                        {t('PUBLIC.LOGIN.FORGOT_PASSWORD')}
                      </Link>
                    </div>
                  }
                />
                <div className={styles.formButton}>
                  <Button
                    className={styles.formButton}
                    buttonType="primary"
                    type="submit"
                    tabIndex={3}
                    disabled={!values.email || !values.password || !!errors.email || !!errors.password || isSubmitting}
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {isSubmitting ? <Loading /> : t('PUBLIC.LOGIN.CTA')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </AuthPageWrap>
  );
};

export default Login;
