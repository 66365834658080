import React, { lazy, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
  ADMIN_CLIENTS,
  AUDIT_REPORT,
  PRODUCTION,
  HOME,
  LOGIN,
  PASSWORD_CHANGE,
  PASSWORD_RESET,
  PROFILE_DELETION,
  SIGNUP,
} from 'utils/routingUtils';
import { userHasAdminRole, userHasSession } from 'utils/userUtils';
import { useCustomisedMomentLocales } from 'utils/dateTimeUtils';
import Home from 'components/features/public/home';
import ProfileDeletion from 'components/features/public/profileDeletion';
import { useTrackedSelector } from '../app/store';
import { getDomain } from 'utils/windowUtils';
import AuditReportWrapper from 'components/features/private/auditReport';

// route splitting: this way the initial app loading is more efficient
const UserHome = lazy(() => import('../features/private/userHome'));
const AdminHome = lazy(() => import('../features/private/admin/home'));

const REFERRER_URL = 'referrerUrl';

const PUBLIC_PAGES = [LOGIN, SIGNUP, PASSWORD_RESET, PASSWORD_CHANGE];

interface Props {
  language: string | undefined;
}

/**
 * Defines a Switch that covers all routes(public and for all possible user roles).
 * Different "home"s exist on purpose so that multiple routs can be loaded lazy when needed.
 *
 * Note: 1) the user's role and 2) whether a user is logged in define which routes are allowed to be accessed
 */
const AppBody = ({ language }: Props): JSX.Element => {
  useCustomisedMomentLocales();
  const { pathname, search } = useLocation();

  const {
    auth: { user },
  } = useTrackedSelector();

  const hasAdminRole = useMemo(() => userHasAdminRole(user), [user]);
  const hasSession = useMemo(() => userHasSession(user), [user]);

  if (language) {
    // our week starts always on Monday
    moment.locale(language, {
      week: {
        dow: 1,
      },
    });
  }
  // console.log(moment().format('dddd'), moment().day(), moment().weekday(), moment().isoWeekday());

  // remember current url in the localStorage and reuse it when app is loaded (helps to navigate to a particular location after login)
  useEffect(() => {
    if (!hasSession && !PUBLIC_PAGES.includes(pathname)) {
      localStorage.setItem(REFERRER_URL, `${getDomain()}/#${pathname}${search}`);
    }
  }, [hasSession, pathname, search]);
  useEffect(() => {
    const referrerUrl = localStorage.getItem(REFERRER_URL);

    if (hasSession && referrerUrl) {
      localStorage.removeItem(REFERRER_URL);
      window.location.href = referrerUrl;
    }
  }, [hasSession, pathname]);

  const publicRoutes = (
    <Routes>
      <Route path={HOME}>
        <Route index element={<Navigate to={LOGIN} />} />

        {/* routes that can happen when a user is logged in and he is not */}
        <Route path={PROFILE_DELETION} element={<ProfileDeletion />} />
        <Route path={AUDIT_REPORT} element={<AuditReportWrapper />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );

  const userRoutes = (
    <Routes>
      <Route path={HOME}>
        <Route index element={<Navigate to={PRODUCTION} />} />

        {/* routes that can happen when a user is logged in and he is not */}
        <Route path={PROFILE_DELETION} element={<ProfileDeletion />} />
        <Route path={AUDIT_REPORT} element={<AuditReportWrapper />} />
        <Route path="*" element={<UserHome />} />
      </Route>
    </Routes>
  );

  const adminRoutes = (
    <Routes>
      <Route path={HOME}>
        <Route index element={<Navigate to={ADMIN_CLIENTS} />} />

        {/* routes that can happen when a user is logged in and he is not */}
        <Route path={PROFILE_DELETION} element={<ProfileDeletion />} />
        <Route path="*" element={<AdminHome />} />
      </Route>
    </Routes>
  );

  return !hasSession ? publicRoutes : hasAdminRole ? adminRoutes : userRoutes;
};

export default AppBody;
