import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { StringMap } from 'types/types';

export enum SearchParams {
  CLIENT = 'client',
  FILTER = 'filter',
  INTERNAL = 'internal',
  ORDER = 'order',
  CLIENT_ID = 'clientId',
  CONCEPT_ID = 'conceptId',
  BRIEF_ID = 'briefId',
  DNA_ID = 'dnaId',
  TEMPLATE_ID = 'templateId',
  CREATIVE_ID = 'creativeId',
}

/**
 * Parses location and returns the same location object plus the parsed search params as a map
 */
const useCustomLocation = () => {
  const location = useLocation();
  const { search } = location;

  const searchParams = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const params = {} as StringMap;
    Object.values(SearchParams)?.forEach((value) => (params[value] = searchParams.get(value)));
    return params;
  }, [search]);

  return { ...location, searchParams };
};

export default useCustomLocation;
