import React from 'react';
import clsx from 'classnames';
import { Props } from './type';

import styles from './RadioButton.module.scss';

const RadioButton = ({
  label,
  disabled,
  alignLabel = 'left',
  style,
  className,
  darkMode = true,
  ...rest
}: Props): JSX.Element => {
  const radioContainerStyle = clsx(styles.radioContainer, {
    [styles.radioContainerDarkMode]: darkMode,
    [`${className}`]: !!className,
    [styles.disabled]: disabled,
    [styles.radioContainerRight]: alignLabel === 'right',
  });

  return (
    <label style={style} className={radioContainerStyle}>
      <p>{label}</p>
      <div className={clsx(styles.container, { [styles.containerDarkMode]: darkMode })}>
        <input {...rest} type="radio" />
        <span className={clsx(styles.checkmark, { [styles.checkmarkDarkMode]: darkMode })} />
      </div>
    </label>
  );
};

export default RadioButton;
