import React, { useCallback, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers } from 'formik';
import { AdConceptInput } from 'types/inputTypes';
import { Button, Checkbox, Input } from 'components/common';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { projectTitleMaxLength } from 'utils/businessUtils';
import logger from 'utils/logger/logger';
import { iso8601DateFormat } from 'utils/dateTimeUtils';
import moment from 'moment';
import DatePicker from 'components/common/datePicker';
import { ReactComponent as DeleteIcon } from 'assets/img/delete.svg';
import ToasterInfo from 'components/common/toasterInfo';
import { ClientPaymentModel } from 'types/types.d';
import { black_0, black_100 } from 'utils/styleUtils';
import { CloseOutlined } from '@ant-design/icons';

import styles from './UpdateAdConceptModal.module.scss';
import { getContactUsMessage } from 'utils/i18nUtils';
import { AdConcept } from 'types/businessTypes';
import { upsertAdConcept } from 'graphql/businessMutations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const validationSchema = (t: TFunction, validateOnSubmit: boolean, existingNames: string[]): any | (() => any) => {
  return Yup.object().shape({
    title: validateOnSubmit
      ? Yup.string()
          .max(projectTitleMaxLength, t('VALIDATION.PROJECT_TITLE_MAX_LENGTH', { length: projectTitleMaxLength }))
          .test('name-test', t('VALIDATION.TITLE_TAKEN'), (value) => {
            return !existingNames.find((i) => i === value);
          })
          .required(t('VALIDATION.REQUIRED'))
      : Yup.string()
          .max(projectTitleMaxLength, t('VALIDATION.PROJECT_TITLE_MAX_LENGTH', { length: projectTitleMaxLength }))
          .optional(),
    deliveryDate: validateOnSubmit ? Yup.string().required(t('VALIDATION.REQUIRED')) : Yup.string().optional(),
  });
};

interface Values {
  title: string;
  deliveryDate: string;
  ugc: boolean;
}

interface Props {
  adConcepts: AdConcept[];
  adConcept: AdConcept;
  onHide: () => void;
  onDone: () => void;
  onDelete: () => void;
}

export default function UpdateAdConceptModal({ adConcepts, adConcept, onHide, onDone, onDelete }: Props): JSX.Element {
  const { t } = useTranslation();

  const [doUpdate] = useMutation<{ upsertAdConcept?: AdConcept }>(upsertAdConcept);

  const [submitTriggered, setSubmitTriggered] = useState(false);

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const handleSubmit = useCallback(
    ({ title, deliveryDate, ugc }: Values, setSubmitting: (isSubmitting: boolean) => void): void => {
      setSubmitting(true);

      doUpdate({
        variables: {
          input: {
            clientId: adConcept.clientId,
            id: adConcept.id,
            paymentModel: adConcept.paymentModel,
            title,
            deliveryDate,
            ugc: adConcept.paymentModel === ClientPaymentModel.STANDARD ? ugc : null,
            briefId: adConcept.briefId,
          } as AdConceptInput,
        },
      })
        .then((result) => {
          if (result?.data) {
            showSuccessNotification(t('PRIVATE.USER.DASHBOARD.UPSERT_CONCEPT.UPDATE_SUCCESS'));
            onDone();
          } else {
            logger.error('No result for ad concept update');
            showErrorNotification();
            setSubmitting(false);
          }
        })
        .catch((error) => {
          logger.error(`Update ad concept error: ${error}`);
          showErrorNotification();
          setSubmitting(false);
        });
    },
    [
      adConcept.briefId,
      adConcept.clientId,
      adConcept.id,
      adConcept.paymentModel,
      doUpdate,
      onDone,
      showErrorNotification,
      showSuccessNotification,
      t,
    ],
  );

  return (
    <Modal
      open={true}
      onCancel={onHide}
      className={styles.modal}
      footer={null}
      maskClosable={false}
      closeIcon={
        <CloseOutlined
          rev={undefined}
          style={{
            color: black_0,
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = black_100)}
          onMouseLeave={(e) => (e.currentTarget.style.color = black_0)}
        />
      }
    >
      <div className={styles.modalHeader}>
        <div className={styles.title}>{t('PRIVATE.USER.DASHBOARD.UPSERT_CONCEPT.UPDATE_TITLE')}</div>
      </div>
      <div className={styles.body}>
        <Formik
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          validationSchema={validationSchema(
            t,
            submitTriggered,
            adConcepts.filter((i) => i.id !== adConcept.id).map((i) => i.title),
          )}
          onSubmit={(values: Values, { setSubmitting }: FormikHelpers<Values>) => handleSubmit(values, setSubmitting)}
          initialValues={{
            title: adConcept.title,
            deliveryDate: adConcept.deliveryDate,
            ugc: adConcept.ugc || false,
          }}
        >
          {({ handleChange, handleBlur, values, errors, isSubmitting, setFieldValue }) => (
            <Form noValidate className={styles.form}>
              <div className={styles.formItem}>
                <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_CONCEPT.TITLE')}</div>
                <Input
                  type="title"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  error={!!errors.title?.length ? errors.title : undefined}
                />
              </div>
              <div className={styles.formItem}>
                <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_CONCEPT.DELIVERY_DATE')}</div>
                <DatePicker
                  date={values.deliveryDate}
                  returnFormat={iso8601DateFormat}
                  onChange={(value) => {
                    setFieldValue('deliveryDate', moment(value).format(iso8601DateFormat), true);
                  }}
                />
              </div>
              {adConcept.paymentModel === ClientPaymentModel.STANDARD && (
                <div className={styles.formItem}>
                  <div className={styles.formItemLabel}>{t('PRIVATE.USER.DASHBOARD.UPSERT_CONCEPT.UGC')}</div>
                  <Checkbox
                    className={styles.ugc}
                    label={t('COMMON.YES')}
                    labelAfter={true}
                    name="ugc"
                    onBlur={handleBlur}
                    checked={values.ugc}
                    onChange={handleChange}
                  />
                </div>
              )}
              <div className={styles.buttons}>
                <Button buttonType="tertiary" icon={<DeleteIcon />} className={styles.deleteIcon} onClick={onDelete}>
                  {t('COMMON.DELETE')}
                </Button>
                <div className={styles.buttonsMain}>
                  <Button className={styles.button} buttonType="secondary" disabled={isSubmitting} onClick={onHide}>
                    {t('COMMON.CANCEL')}
                  </Button>
                  <Button
                    className={styles.button}
                    buttonType="primary"
                    type="submit"
                    disabled={!values.title.length || !!errors.title || isSubmitting}
                    onClick={() => setSubmitTriggered(true)}
                  >
                    {t('COMMON.UPDATE')}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
