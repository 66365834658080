import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AGENCY } from 'utils/routingUtils';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { getObjectUrlParams } from 'utils/stringUtils';
import { useNavigate } from 'react-router-dom';
import { Client, Clients, SecurityRole } from 'types/types.d';
import { getClients } from 'graphql/queries';
import { useTrackedSelector } from 'components/app/store';
import { OrderBy, SearchPaging, Sorting } from 'types/inputTypes';
import { userHasAnyRole } from 'utils/userUtils';

export const AgencyContext = createContext<AgencyContextProps | null>(null);

export const filtersInitialState = {
  [SearchParams.ORDER]: '',
  [SearchParams.CLIENT_ID]: '',
};

export const buildAgencyUrl = (input: {
  [SearchParams.CLIENT_ID]?: string | null;
  [SearchParams.ORDER]?: string | null;
}): string => {
  const filters = {
    [SearchParams.CLIENT_ID]: input[SearchParams.CLIENT_ID] || filtersInitialState[SearchParams.CLIENT_ID],
    [SearchParams.ORDER]: input[SearchParams.ORDER] || filtersInitialState[SearchParams.ORDER],
  };
  const urlParams = getObjectUrlParams(filters);
  return `/${AGENCY}?${urlParams}`;
};

export interface AgencyContextProps {
  loading: boolean;
  error: boolean;
  clients: Client[];

  selectedClientId: string;
  onClientSelected: (clientId: string, fromEditor?: boolean) => void;

  page: number;
  pageSize: number;
  total: number;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
}

const AgencyProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();

  const {
    auth: { user },
  } = useTrackedSelector();

  const {
    searchParams: { [SearchParams.CLIENT_ID]: _clientId = filtersInitialState[SearchParams.CLIENT_ID] },
  } = useCustomLocation();

  const [doFetchClients, { data: clientsData, loading: clientsLoading, error: clientsError }] = useLazyQuery<{
    getClients: Clients;
  }>(getClients);

  const [selectedClientId, setSelectedClientId] = useState<string>(_clientId || user?.clientId || '');

  const isPgUserOrCreator = useMemo(() => userHasAnyRole(user, [SecurityRole.PG_USER, SecurityRole.CREATOR]), [user]);

  const clients = useMemo(() => {
    const items = clientsData?.getClients?.items || [];
    if (!selectedClientId && !!items.length) setSelectedClientId(items[0].id);
    return items;
  }, [clientsData?.getClients?.items, selectedClientId]);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.CLIENT_ID]: selectedClientId || _clientId || filtersInitialState[SearchParams.CLIENT_ID],
      }),
    [_clientId, selectedClientId],
  );

  const onClientSelected = useCallback(
    (clientId: string, fromEditor = false) => {
      setSelectedClientId(clientId);
      if (!fromEditor) {
        // setSelectedClientId('');
        navigate(
          buildAgencyUrl({
            [SearchParams.CLIENT_ID]: clientId,
          }),
        );
      }
    },
    [navigate],
  );

  // fetch all clients if it's a PG user
  useEffect(() => {
    if (isPgUserOrCreator) {
      doFetchClients({
        variables: {
          sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
          paging: { page: 0, perPage: 100 } as SearchPaging,
        },
      });
    }
  }, [doFetchClients, isPgUserOrCreator]);

  useEffect(() => {
    navigate(`/${AGENCY}?${urlParams}`, { replace: true });
  }, [navigate, urlParams]);

  const value = {
    loading: clientsLoading,
    error: !!clientsError,
    clients,

    selectedClientId,
    onClientSelected,
  } as AgencyContextProps;

  return <AgencyContext.Provider value={value}>{children}</AgencyContext.Provider>;
};

export default AgencyProvider;
