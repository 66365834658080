/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { ANALYTICS } from 'utils/routingUtils';
import { getObjectUrlParams } from 'utils/stringUtils';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { useNavigate } from 'react-router-dom';
import { userHasClientUserRole, userHasPgUserRole } from 'utils/userUtils';
import { useTrackedSelector } from 'components/app/store';
import { useLazyQuery } from '@apollo/client';
import { getAnalytics } from 'graphql/businessQueries';
import { Analytics } from 'types/businessTypes';
import { Client, Clients } from 'types/types';
import { getClients } from 'graphql/queries';
import { OrderBy, SearchPaging, Sorting } from 'types/inputTypes';

export const AnalyticsContext = createContext({});

export const filtersInitialState = {
  [SearchParams.CLIENT_ID]: '',
  [SearchParams.CONCEPT_ID]: '',
};

export const buildAnalyticsUrl = (input: {
  [SearchParams.CLIENT_ID]?: string | null;
  [SearchParams.CONCEPT_ID]?: string | null;
}): string => {
  const filters = {
    [SearchParams.CLIENT_ID]: input[SearchParams.CLIENT_ID] || filtersInitialState[SearchParams.CLIENT_ID],
    [SearchParams.CONCEPT_ID]: input[SearchParams.CONCEPT_ID] || filtersInitialState[SearchParams.CONCEPT_ID],
  };

  const urlParams = getObjectUrlParams(filters);

  return `/${ANALYTICS}?${urlParams}`;
};

export interface AnalyticsContextProps {
  loading: boolean;
  error: boolean;
  analytics: Analytics | null;

  onClientSelected: (id: string) => void;
  selectedClientId: string | null;
  clients: Client[];
}

const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const {
    searchParams: {
      [SearchParams.CLIENT_ID]: _clientId = filtersInitialState[SearchParams.CLIENT_ID],
      [SearchParams.CONCEPT_ID]: _projectId = filtersInitialState[SearchParams.CONCEPT_ID],
    },
  } = useCustomLocation();

  const {
    auth: { user },
  } = useTrackedSelector();

  const [doFetchClients, { data: clientsData, loading: clientsLoading, error: clientsError }] = useLazyQuery<{
    getClients: Clients;
  }>(getClients);
  const [doGetAnalytics, { data: analyticsData, loading: analyticsLoading, error: analyticsError }] = useLazyQuery<{
    getAnalytics?: Analytics;
  }>(getAnalytics);

  const [selectedClientId, setSelectedClientId] = useState<string>(_clientId || '');
  const [selectedProjectId, setSelectedProjectId] = useState<string>(_projectId || '');

  const clients = useMemo(() => clientsData?.getClients?.items || [], [clientsData?.getClients?.items]);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.CLIENT_ID]: selectedClientId || _clientId || filtersInitialState[SearchParams.CLIENT_ID],
        [SearchParams.CONCEPT_ID]: selectedProjectId || _projectId || filtersInitialState[SearchParams.CONCEPT_ID],
      }),
    [_clientId, _projectId, selectedClientId, selectedProjectId],
  );

  const fetchAnalytics = useCallback(() => {
    doGetAnalytics({ variables: { clientId: selectedClientId } });
  }, [doGetAnalytics, selectedClientId]);

  const onClientSelected = useCallback(
    (id: string) => {
      setSelectedClientId(id);
      setSelectedProjectId('');
      navigate(
        buildAnalyticsUrl({
          [SearchParams.CLIENT_ID]: id,
          [SearchParams.CONCEPT_ID]: '',
        }),
      );
    },
    [navigate],
  );

  // set client id for client user
  useEffect(() => {
    if (user && userHasClientUserRole(user) && !selectedClientId?.length) {
      setSelectedClientId(user.clientId || '');
    }
  }, [selectedClientId?.length, user]);

  // initial fetch of all entities
  useEffect(() => {
    if (!user) return;
    if (userHasClientUserRole(user)) return;

    if (userHasPgUserRole(user)) {
      doFetchClients({
        variables: {
          sorting: { orderBy: OrderBy.CreatedDateDesc } as Sorting,
          paging: { page: 0, perPage: 100 } as SearchPaging,
        },
      });
    }
  }, [doFetchClients, user]);
  useEffect(() => {
    if (!user) return;
    if (userHasClientUserRole(user) && !selectedClientId?.length) return;

    fetchAnalytics();
  }, [fetchAnalytics, selectedClientId, user]);

  // select the very first client if no client selected
  useEffect(() => {
    if (!selectedClientId?.length && !!clients.length) {
      setSelectedClientId(clients[0].id);
    }
  }, [clients, selectedClientId?.length]);

  useEffect(() => {
    navigate(`/${ANALYTICS}?${urlParams}`, { replace: true });
  }, [navigate, urlParams]);

  const value = {
    loading: clientsLoading || analyticsLoading,
    error: !!clientsError || !!analyticsError,
    analytics: analyticsData?.getAnalytics || null,

    selectedClientId,
    clients,
    onClientSelected,
  } as AnalyticsContextProps;

  // console.log(value);
  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsProvider;
