import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'classnames';
import OutsideClick from 'components/common/outSideClick';
import DropDownMenu from 'components/common/dropdownMenu';
import { Variant } from 'components/common/dropdownMenu/types';
import { Props, ShowValues } from 'components/common/multiSelectDropdown/type';
import { ReactComponent as ChevronDownIcon } from 'assets/img/chevronDown.svg';

import styles from './MultiSelectDropDown.module.scss';

const MultiSelectDropDown = ({
  variants,
  disabled,
  takeValues,
  className,
  menuContainerClassname,
  darkMode = true,
  ...rest
}: Props): JSX.Element => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [chosenValues, setChosenValues] = useState<Variant[]>([]);
  const [showValues, setShowValues] = useState<ShowValues>({
    inputValue: '',
    inputCount: 0,
  });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const containerStyle = clsx(styles.container, {
    [`${className}`]: !!className,
  });

  const multiSelectDropDownStyle = clsx(styles.multiSelectDropDownWrapper, {
    [styles.focused]: openDropdown,
    [styles.multiSelectDropDownWrapperDarkMode]: darkMode,
    [styles.disabled]: disabled,
  });

  const inputValue = useMemo(() => {
    if (showValues.inputValue.length >= 55) {
      return showValues.inputValue.slice(0, 55) + '...';
    }
    return showValues.inputValue;
  }, [showValues]);

  const openDropDown = () => {
    if (!disabled) {
      setOpenDropdown(!openDropdown);
    }
  };

  const chooseValue = useCallback(
    (arg: Variant) => {
      let inputValue = '';
      let inputCount = 0;
      const res = chosenValues.map((value: Variant) => {
        if (value.id === arg.id) {
          value.selected = !value.selected;
        }

        if (value.selected) {
          if (inputValue) {
            inputValue += ', ';
          }
          inputValue += value.name;
          inputCount++;
          setShowValues({
            inputValue,
            inputCount,
          });
        }
        if (!inputValue) {
          setShowValues({ inputValue, inputCount });
        }

        return value;
      });

      if (res) {
        setChosenValues(res);
        takeValues(chosenValues.filter(({ selected }) => selected));
      }
    },
    [chosenValues, takeValues],
  );

  // Init internal state from passed values
  useEffect(() => {
    setChosenValues(variants);

    setShowValues({
      inputValue: variants
        .filter(({ selected }) => selected)
        .map(({ name }) => name)
        .join(', '),
      inputCount: variants.filter(({ selected }) => selected).length,
    });
  }, [variants]);

  // Filter variants based on search term
  const filteredVariants = useMemo(
    () => variants.filter((variant) => variant.name.toLowerCase().includes(searchTerm.toLowerCase())),
    [variants, searchTerm],
  );

  return (
    <div className={containerStyle} {...rest}>
      <OutsideClick onOutsideClick={() => setOpenDropdown(false)}>
        <div className={styles.container} onClick={openDropDown}>
          <div className={multiSelectDropDownStyle}>
            <div className={styles.inputContainer}>
              <p>{inputValue}</p>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search..."
                className={styles.searchInput}
              />
              <div>{showValues.inputCount}</div>
            </div>
            <div
              className={clsx(styles.downIcon, {
                [styles.downIconDarkMode]: darkMode,
              })}
            >
              <ChevronDownIcon />
            </div>
          </div>
        </div>
        {!disabled && variants.length > 0 && (
          <div className={clsx(styles.menu, { [styles.menuDarkMode]: darkMode })}>
            <DropDownMenu
              open={openDropdown}
              multiSelect={true}
              variants={filteredVariants}
              chooseValue={chooseValue}
              containerClassname={menuContainerClassname}
              darkMode={darkMode}
            />
          </div>
        )}
      </OutsideClick>
    </div>
  );
};

export default MultiSelectDropDown;
