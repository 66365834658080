import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { AdConcept } from 'types/businessTypes';
import { deleteAdConcept } from 'graphql/businessMutations';
import { Button } from 'components/common';
import { toast } from 'react-toastify';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import logger from 'utils/logger/logger';
import ToasterInfo from 'components/common/toasterInfo';
import { CloseOutlined } from '@ant-design/icons';
import { black_0, black_100 } from 'utils/styleUtils';
import { getContactUsMessage } from 'utils/i18nUtils';

import styles from './DeleteAdConceptModal.module.scss';

interface Props {
  adConcept: AdConcept;
  onHide: () => void;
  onDone: () => void;
}

export default function DeleteAdConceptModal({ adConcept, onHide, onDone }: Props): JSX.Element {
  const { t } = useTranslation();

  const [doDelete] = useMutation<{ deleteAdConcept: boolean }>(deleteAdConcept);

  const [deleting, setDeleting] = useState(false);

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const handleSubmit = useCallback((): void => {
    setDeleting(true);

    doDelete({ variables: { id: adConcept.id } })
      .then((result) => {
        if (result?.data) {
          showSuccessNotification(t('PRIVATE.USER.DASHBOARD.DELETE_CONCEPT.SUCCESS'));
          onDone();
        } else {
          logger.error('No result for ad concept delete');
          showErrorNotification();
          setDeleting(false);
        }
      })
      .catch((error) => {
        logger.error(`Delete ad concept error: ${error}`);
        showErrorNotification();
        setDeleting(false);
      });
  }, [doDelete, onDone, adConcept.id, showErrorNotification, showSuccessNotification, t]);

  return (
    <Modal
      open={true}
      onCancel={onHide}
      className={styles.modal}
      footer={null}
      maskClosable={false}
      closeIcon={
        <CloseOutlined
          rev={undefined}
          style={{
            color: black_0,
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = black_100)}
          onMouseLeave={(e) => (e.currentTarget.style.color = black_0)}
        />
      }
    >
      <div className={styles.modalHeader}>
        <div className={styles.title}>{t('PRIVATE.USER.DASHBOARD.DELETE_CONCEPT.TITLE')}</div>
      </div>
      <div className={styles.body}>
        <div className={styles.msg}>{t('PRIVATE.USER.DASHBOARD.DELETE_CONCEPT.MSG')}</div>
        <div className={styles.project}>{adConcept.title}</div>
        <div className={styles.buttons}>
          <Button className={styles.button} buttonType="secondary" disabled={deleting} onClick={onHide}>
            {t('COMMON.CANCEL')}
          </Button>
          <Button
            className={styles.button}
            buttonType="primary"
            type="submit"
            disabled={deleting}
            onClick={() => handleSubmit()}
          >
            {t('COMMON.DELETE')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
