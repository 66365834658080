import { gql } from '@apollo/client';
import { fragments } from './queries';

export const updateUser = gql`
  mutation (
    $email: String
    $firstName: String
    $lastName: String
    $profilePic: String
    $clientTeamRole: ClientTeamRole
  ) {
    updateUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      profilePic: $profilePic
      clientTeamRole: $clientTeamRole
    ) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const updateProjectViewLayout = gql`
  mutation ($layout: ProjectViewLayout!) {
    updateProjectViewLayout(layout: $layout) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const requestEmailChange = gql`
  mutation ($email: String, $newEmail: String!) {
    requestEmailChange(email: $email, newEmail: $newEmail) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const changePassword = gql`
  mutation ($email: String, $currentPassword: String!, $newPassword: String!) {
    changePassword(email: $email, currentPassword: $currentPassword, newPassword: $newPassword) {
      ...User
    }
  }
  ${fragments.user}
  ${fragments.userSettings}
`;

export const requestUserDeletion = gql`
  mutation ($email: String) {
    requestUserDeletion(email: $email)
  }
`;

export const updateUserNotificationSettings = gql`
  mutation ($email: String, $settings: [UserNotificationSettingInput!]!) {
    updateUserNotificationSettings(email: $email, settings: $settings) {
      ...UserNotificationSettings
    }
  }
  ${fragments.userNotificationSettings}
  ${fragments.userNotificationSetting}
`;

export const createInvitation = gql`
  mutation (
    $email: String!
    $role: String!
    $clientId: String
    $clientTeamRole: ClientTeamRole
    $projectId: String
    $whitelabelId: String
  ) {
    createInvitation(
      email: $email
      role: $role
      clientId: $clientId
      clientTeamRole: $clientTeamRole
      projectId: $projectId
      whitelabelId: $whitelabelId
    ) {
      ...Invitation
    }
  }
  ${fragments.invitation}
`;

export const createClient = gql`
  mutation (
    $name: String!
    $paymentModels: [ClientPaymentModel!]!
    $slackChannel: String
    $painPoints: [String!]
    $productFeatures: [String!]
    $products: [String!]
    $benefits: [String!]
    $valuePropositions: [String!]
    $personas: [ClientPersonaInput!]
    $ctas: [String!]
    $creativeLeads: [String!]
    $creativeStrategists: [String!]
    $graphicDesigners: [String!]
    $motionDesigners: [String!]
    $copywriters: [String!]
    $creators: [String!]
    $ideators: [String!]
    $visualFeatures: [String!]
    $facebookAdAccountId: String
    $facebookAdAccountAccessToken: String
    $facebookImportExternalAds: Boolean
    $facebookAdEvents: [AdEvent!]
    $tikTokAdvertiserId: String
    $tikTokAdvertiserAccessToken: String
    $tikTokImportExternalAds: Boolean
    $tikTokAdEvents: [AdEvent!]
    $asanaIds: [String!]
    $whitelabel: String
  ) {
    createClient(
      name: $name
      paymentModels: $paymentModels
      slackChannel: $slackChannel
      painPoints: $painPoints
      productFeatures: $productFeatures
      products: $products
      benefits: $benefits
      valuePropositions: $valuePropositions
      personas: $personas
      ctas: $ctas
      creativeLeads: $creativeLeads
      creativeStrategists: $creativeStrategists
      graphicDesigners: $graphicDesigners
      motionDesigners: $motionDesigners
      copywriters: $copywriters
      creators: $creators
      ideators: $ideators
      visualFeatures: $visualFeatures
      facebookAdAccountId: $facebookAdAccountId
      facebookAdAccountAccessToken: $facebookAdAccountAccessToken
      facebookImportExternalAds: $facebookImportExternalAds
      facebookAdEvents: $facebookAdEvents
      tikTokAdvertiserId: $tikTokAdvertiserId
      tikTokAdvertiserAccessToken: $tikTokAdvertiserAccessToken
      tikTokImportExternalAds: $tikTokImportExternalAds
      tikTokAdEvents: $tikTokAdEvents
      asanaIds: $asanaIds
      whitelabel: $whitelabel
    ) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const updateClient = gql`
  mutation (
    $id: String!
    $name: String
    $paymentModels: [ClientPaymentModel!]
    $slackChannel: String
    $painPoints: [String!]
    $productFeatures: [String!]
    $products: [String!]
    $benefits: [String!]
    $valuePropositions: [String!]
    $personas: [ClientPersonaInput!]
    $ctas: [String!]
    $creativeLeads: [String!]
    $creativeStrategists: [String!]
    $graphicDesigners: [String!]
    $motionDesigners: [String!]
    $copywriters: [String!]
    $creators: [String!]
    $ideators: [String!]
    $visualFeatures: [String!]
    $facebookAdAccountId: String
    $facebookAdAccountAccessToken: String
    $facebookImportExternalAds: Boolean
    $facebookAdEvents: [AdEvent!]
    $tikTokAdvertiserId: String
    $tikTokAdvertiserAccessToken: String
    $tikTokImportExternalAds: Boolean
    $tikTokAdEvents: [AdEvent!]
    $asanaIds: [String!]
    $whitelabel: String
  ) {
    updateClient(
      id: $id
      name: $name
      paymentModels: $paymentModels
      slackChannel: $slackChannel
      painPoints: $painPoints
      productFeatures: $productFeatures
      products: $products
      benefits: $benefits
      valuePropositions: $valuePropositions
      personas: $personas
      ctas: $ctas
      creativeLeads: $creativeLeads
      creativeStrategists: $creativeStrategists
      graphicDesigners: $graphicDesigners
      motionDesigners: $motionDesigners
      copywriters: $copywriters
      creators: $creators
      ideators: $ideators
      visualFeatures: $visualFeatures
      facebookAdAccountId: $facebookAdAccountId
      facebookAdAccountAccessToken: $facebookAdAccountAccessToken
      facebookImportExternalAds: $facebookImportExternalAds
      facebookAdEvents: $facebookAdEvents
      tikTokAdvertiserId: $tikTokAdvertiserId
      tikTokAdvertiserAccessToken: $tikTokAdvertiserAccessToken
      tikTokImportExternalAds: $tikTokImportExternalAds
      tikTokAdEvents: $tikTokAdEvents
      asanaIds: $asanaIds
      whitelabel: $whitelabel
    ) {
      ...Client
    }
  }
  ${fragments.client}
  ${fragments.clientPersona}
`;

export const deleteClient = gql`
  mutation ($id: String!) {
    deleteClient(id: $id)
  }
`;

export const createWhitelabel = gql`
  mutation (
    $domain: String!
    $name: String
    $logoContent: String
    $logoName: String
    $faviconContent: String
    $faviconName: String
    $metaDescription: String
  ) {
    createWhitelabel(
      domain: $domain
      name: $name
      logoContent: $logoContent
      logoName: $logoName
      faviconContent: $faviconContent
      faviconName: $faviconName
      metaDescription: $metaDescription
    ) {
      ...Whitelabel
    }
  }
  ${fragments.whitelabel}
`;

export const updateWhitelabel = gql`
  mutation (
    $id: String!
    $domain: String
    $name: String
    $logoContent: String
    $logoName: String
    $faviconContent: String
    $faviconName: String
    $metaDescription: String
  ) {
    updateWhitelabel(
      id: $id
      domain: $domain
      name: $name
      logoContent: $logoContent
      logoName: $logoName
      faviconContent: $faviconContent
      faviconName: $faviconName
      metaDescription: $metaDescription
    ) {
      ...Whitelabel
    }
  }
  ${fragments.whitelabel}
`;

export const deleteWhitelabel = gql`
  mutation ($id: String!) {
    deleteWhitelabel(id: $id)
  }
`;
