import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getObjectUrlParams } from 'utils/stringUtils';
import { BRIEF } from 'utils/routingUtils';
import { Brief, BriefConceptMaterial, BriefState, AdConcept } from 'types/businessTypes.d';
import { getBrief, getAdConcept } from 'graphql/businessQueries';
import { toast } from 'react-toastify';
import useCustomLocation, { SearchParams } from 'hooks/useCustomLocation';
import { useTrackedSelector } from 'components/app/store';
import { buildDashboardUrl } from '../dashboard/DashboardProvider';
import { Client, ClientPaymentModel, GRAPHQL_TYPENAME, SocialMediaChannel } from 'types/types.d';
import cloneDeep from 'lodash/cloneDeep';
import { deepEqual } from 'utils/objectUtils';
import { userHasCreatorRole } from 'utils/userUtils';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { useTranslation } from 'react-i18next';
import logger from 'utils/logger/logger';
import { createBrief, updateBrief } from 'graphql/businessMutations';
import ToasterInfo from 'components/common/toasterInfo';
import { getClient } from 'graphql/queries';
import { getContactUsMessage } from 'utils/i18nUtils';

export const BriefContext = createContext({});

export const filtersInitialState = {
  [SearchParams.CLIENT_ID]: '',
  [SearchParams.CONCEPT_ID]: '',
  [SearchParams.BRIEF_ID]: '',
};

export const buildBriefUrl = (input: {
  [SearchParams.CLIENT_ID]?: string | null;
  [SearchParams.CONCEPT_ID]?: string | null;
  [SearchParams.BRIEF_ID]?: string | null;
}): string => {
  const filters = {
    [SearchParams.CLIENT_ID]: input[SearchParams.CLIENT_ID] || filtersInitialState[SearchParams.CLIENT_ID],
    [SearchParams.CONCEPT_ID]: input[SearchParams.CONCEPT_ID] || filtersInitialState[SearchParams.CONCEPT_ID],
    [SearchParams.BRIEF_ID]: input[SearchParams.BRIEF_ID] || filtersInitialState[SearchParams.BRIEF_ID],
  };

  const urlParams = getObjectUrlParams(filters);

  return `/${BRIEF}?${urlParams}`;
};

export enum BriefField {
  // main
  TITLE = 'title',
  CHANNEL = 'channel',
  UGC = 'ugc',
  DEADLINE_FOR_CREATORS = 'deadlineForCreators',

  // concept
  DESCRIPTION = 'description',
  AD_REFERENCES = 'adReferences',
  PRODUCT_FEATURES = 'productFeatures',
  VALUE_PROPOSITIONS = 'valuePropositions',
  PAST_LEARNINGS = 'pastLearnings',
  MANDATORY_ELEMENTS = 'mandatoryElements',
  MATERIALS = 'materials',

  // deliverables
  NUMBER_OF_CREATORS = 'creatorsNumber',
  VIDEOS_NUMBER = 'videosNumber',
  LAYOUTS = 'layouts',
  VIDEO_LENGTH = 'videoLength',
  ASSETS_VARIATIONS_NUMBER = 'assetsVariationsNumber',
  ASSETS_VARIATIONS_TYPES = 'assetsVariationsTypes',
  ASSETS_VARIATIONS_MOTION_NUMBER = 'assetsVariationsMotionNumber',
  ASSETS_VARIATIONS_MOTION_TYPES = 'assetsVariationsMotionTypes',
  ASSETS_VARIATIONS_STATICS_NUMBER = 'assetsVariationsStaticsNumber',
  ASSETS_VARIATIONS_STATICS_TYPES = 'assetsVariationsStaticsTypes',

  // ugc
  SCRIPTS = 'script',
  SCRIPT_TONES = 'scriptTones',
  SCRIPT_TONES_OTHER = 'scriptTonesOther',
  REQUIREMENTS_FOR_CREATORS = 'requirementsForCreators',
  MATERIALS_UGC = 'materials',

  // creators
  CREATORS_NUMBERS = 'creatorsNumber',
  LOCATIONS = 'locations',
  LOCATION_OTHER = 'locationOther',
  LANGUAGES = 'languages',
  LANGUAGE_OTHER = 'languageOther',
  GENDERS = 'genders',
  AGE = 'age',
  NOTES = 'notes',
  REQUIREMENTS_FOR_CREATORS_CREATORS = 'requirementsForCreators',

  // copy
  COPY_TEXT = 'copyText',
  COPY_TONES = 'copyTones',
  COPY_TONE_OTHER = 'copyToneOther',

  // creative
  VISUAL_FEATURES = 'visualFeatures',
  CREATIVE_MANDATORY_ELEMENTS = 'mandatoryElements',
}

const getDefaultBrief = (
  adConceptId: string,
  paymentModel: ClientPaymentModel,
  isInitiallyUgc: boolean | null,
): Brief => ({
  projectId: adConceptId,
  id: '',
  paymentModel,
  state: BriefState.DRAFT,
  main: {
    title: '',
    channel: SocialMediaChannel.TIK_TOK,
    ugc: isInitiallyUgc,
    deadlineForCreators: null,
  },
  concept: {
    description: '',
    adReferences: '',
    productFeatures: '',
    valuePropositions: '',
    pastLearnings: '',
    mandatoryElements: '',
    materials: [],
  },
  deliverables: {
    creatorsNumber: 1,
    videosNumber: 1,
    layouts: [],
    videoLength: [],

    assetsVariationsNumber: 1,
    assetsVariationsTypes: [],
    assetsVariationsMotionNumber: 1,
    assetsVariationsMotionTypes: [],
    assetsVariationsStaticsNumber: 1,
    assetsVariationsStaticsTypes: [],
  },
  ugc: {
    script: '',
    scriptTones: [],
    scriptTonesOther: '',
    requirementsForCreators: '',
    materials: [],
  },
  creators: {
    creatorsNumber: 1,
    locations: [],
    locationOther: '',
    languages: [],
    languageOther: '',
    genders: [],
    age: [],
    notes: '',
  },
  copy: {
    copyText: '',
    copyTones: [],
    copyToneOther: '',
  },
  creative: {
    visualFeatures: '',
    mandatoryElements: '',
  },
  comments: [],
});

export interface BriefContextProps {
  initialLoading: boolean;
  loading: boolean;
  error: boolean;

  initialBrief: Brief | null;
  brief: Brief | null;

  client: Client | null;
  adConcept: AdConcept | null;

  isBriefChanged: boolean;
  isReadOnlyMode: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateBriefMainField: (item: string, value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateBriefConceptField: (item: string, value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateBriefDeliverablesField: (item: string, value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateBriefUgcField: (item: string, value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateBriefCreatorsField: (item: string, value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateBriefCopyField: (item: string, value: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateBriefCreativeField: (item: string, value: any) => void;

  onSaveBrief: (draft: boolean) => void;
  onCancel: () => void;
}

const BriefProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isInitiallyUgc = location.state?.ugc || null;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const paymentModel = location.state?.paymentModel || ClientPaymentModel.STANDARD;

  const {
    searchParams: {
      [SearchParams.CLIENT_ID]: _clientId = filtersInitialState[SearchParams.CLIENT_ID],
      [SearchParams.CONCEPT_ID]: _adConceptId = filtersInitialState[SearchParams.CONCEPT_ID],
      [SearchParams.BRIEF_ID]: _briefId = filtersInitialState[SearchParams.BRIEF_ID],
    },
  } = useCustomLocation();

  const [doFetchBrief, { loading: briefLoading, error: briefError }] = useLazyQuery<{
    getBrief?: Brief;
  }>(getBrief);
  const [doCreateBrief, { loading: createBriefLoading }] = useMutation<{
    createBrief?: Brief;
  }>(createBrief);
  const [doUpdateBrief, { loading: updateBriefLoading }] = useMutation<{
    updateBrief?: Brief;
  }>(updateBrief);

  const [doFetchAdConcept, { data: conceptData, loading: conceptLoading, error: conceptError }] = useLazyQuery<{
    getAdConcept?: AdConcept;
  }>(getAdConcept);
  const [doFetchClient, { data: clientData, loading: clientLoading, error: clientError }] = useLazyQuery<{
    getClient?: Client;
  }>(getClient);

  const {
    auth: { user },
  } = useTrackedSelector();

  const defaultBrief = useMemo(
    () => getDefaultBrief(_adConceptId || '', paymentModel, isInitiallyUgc),
    [_adConceptId, isInitiallyUgc, paymentModel],
  );

  const [initialLoading, setInitialLoading] = useState(true);
  const [initialBrief, setInitialBrief] = useState(cloneDeep(defaultBrief));
  const [brief, setBrief] = useState(cloneDeep(defaultBrief));

  const client = useMemo(
    () => (!clientLoading && !clientError && clientData?.getClient ? clientData.getClient : null),
    [clientData?.getClient, clientError, clientLoading],
  );
  const adConcept = useMemo(
    () => (!conceptLoading && !conceptError && conceptData?.getAdConcept ? conceptData.getAdConcept : null),
    [conceptData?.getAdConcept, conceptError, conceptLoading],
  );

  // ignore attributes that are calculated by BE or are read-only
  const keysToIgnore = useMemo(() => new Set([]), []);
  const isBriefChanged = useMemo(() => {
    return !deepEqual(brief, initialBrief, keysToIgnore);
  }, [brief, initialBrief, keysToIgnore]);

  const isReadOnlyMode = useMemo(() => userHasCreatorRole(user), [user]);

  // builds URL search part from the internal state
  const urlParams = useMemo(
    () =>
      getObjectUrlParams({
        [SearchParams.CLIENT_ID]: _clientId || filtersInitialState[SearchParams.CLIENT_ID],
        [SearchParams.CONCEPT_ID]: _adConceptId || filtersInitialState[SearchParams.CONCEPT_ID],
        [SearchParams.BRIEF_ID]: _briefId || filtersInitialState[SearchParams.BRIEF_ID],
      }),
    [_briefId, _clientId, _adConceptId],
  );

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const onSaveBrief = useCallback(
    (draft: boolean) => {
      const { id } = brief;
      const create = !id?.length;
      const doSave = create ? doCreateBrief : doUpdateBrief;

      const variables = create
        ? {
            projectId: _adConceptId,
            paymentModel: brief.paymentModel,
            state: draft ? BriefState.DRAFT : BriefState.COMPLETED,
            main: brief.main,
            concept: brief.concept,
            deliverables: brief.deliverables,
            ugc: brief.ugc,
            creators: brief.creators,
            copy: brief.copy,
            creative: brief.creative,
          }
        : {
            id,
            paymentModel: brief.paymentModel,
            state: draft ? BriefState.DRAFT : BriefState.COMPLETED,
            main: brief.main,
            concept: brief.concept,
            deliverables: brief.deliverables,
            ugc: brief.ugc,
            creators: brief.creators,
            copy: brief.copy,
            creative: brief.creative,
            comments: brief.comments,
          };

      // delete graphql-specific fields
      if (!create) {
        ['main', 'concept', 'deliverables', 'ugc', 'creators', 'copy', 'creative', 'comments'].forEach((i) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (variables[`${i}`]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete variables[`${i}`][GRAPHQL_TYPENAME];
          }
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        variables.concept?.materials?.forEach((i: BriefConceptMaterial) => delete i[GRAPHQL_TYPENAME]);
      }

      doSave({ variables })
        .then((result) => {
          const data = result.data;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const updatedBrief = create ? data?.createBrief : data?.updateBrief;
          if (updatedBrief) {
            showSuccessNotification(t('PRIVATE.USER.BRIEF.SUCCESS'));
            setBrief((prevState) => ({ ...prevState, ...cloneDeep(updatedBrief) }));
            setInitialBrief((prevState) => ({ ...prevState, ...cloneDeep(updatedBrief) }));

            // replace the url because now we have a saved brief
            navigate(
              buildBriefUrl({
                [SearchParams.CLIENT_ID]: _clientId,
                [SearchParams.CONCEPT_ID]: _adConceptId,
                [SearchParams.BRIEF_ID]: updatedBrief.id,
              }),
              { replace: true },
            );
          } else {
            logger.error(`No result for brief ${create ? 'create' : 'update'}`);
            showErrorNotification();
          }
        })
        .catch((error) => {
          logger.error(`Error ${create ? 'create' : 'update'} brief: ${error}`);
          showErrorNotification();
        });
    },
    [
      _clientId,
      _adConceptId,
      brief,
      doCreateBrief,
      doUpdateBrief,
      navigate,
      showErrorNotification,
      showSuccessNotification,
      t,
    ],
  );

  const onCancel = useCallback(() => {
    navigate(
      buildDashboardUrl({
        [SearchParams.CLIENT_ID]: _clientId,
        [SearchParams.CONCEPT_ID]: _adConceptId,
      }),
    );
  }, [_clientId, _adConceptId, navigate]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateBriefMainField = useCallback((item: string, value: any) => {
    setBrief((prevState) => ({ ...prevState, main: { ...prevState.main, [item]: value } }));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateBriefConceptField = useCallback((item: string, value: any) => {
    setBrief((prevState) => ({ ...prevState, concept: { ...prevState.concept, [item]: value } }));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateBriefDeliverablesField = useCallback((item: string, value: any) => {
    setBrief((prevState) => ({ ...prevState, deliverables: { ...prevState.deliverables, [item]: value } }));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateBriefUgcField = useCallback((item: string, value: any) => {
    setBrief((prevState) => ({ ...prevState, ugc: { ...prevState.ugc, [item]: value } }));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateBriefCreatorsField = useCallback((item: string, value: any) => {
    setBrief((prevState) => ({ ...prevState, creators: { ...prevState.creators, [item]: value } }));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateBriefCopyField = useCallback((item: string, value: any) => {
    setBrief((prevState) => ({ ...prevState, copy: { ...prevState.copy, [item]: value } }));
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateBriefCreativeField = useCallback((item: string, value: any) => {
    setBrief((prevState) => ({ ...prevState, creative: { ...prevState.creative, [item]: value } }));
  }, []);

  // initial fetch
  useEffect(() => {
    if (!brief.id.length && !!_briefId) {
      doFetchBrief({ variables: { id: _briefId } })
        .then((result) => {
          const fetchedBrief = result?.data?.getBrief;
          if (fetchedBrief) {
            setInitialBrief(cloneDeep(fetchedBrief));
            setBrief(cloneDeep(fetchedBrief));
          } else {
            showErrorNotification();
            onCancel();
          }
        })
        .catch((e) => {
          logger.error(`Error fetching brief ${_briefId}: ${e}`);
          showErrorNotification();
          onCancel();
        });
    }
  }, [_briefId, brief.id, doFetchBrief, onCancel, showErrorNotification]);

  useEffect(() => {
    if (!!_clientId) {
      doFetchClient({ variables: { id: _clientId } });
    }
  }, [_clientId, doFetchClient]);

  useEffect(() => {
    if (!!_adConceptId) {
      doFetchAdConcept({ variables: { id: _adConceptId } }).then((result) => {
        if (result?.data?.getAdConcept) {
          onUpdateBriefMainField(BriefField.TITLE, result?.data?.getAdConcept?.title);
        }
      });
    }
  }, [_adConceptId, doFetchAdConcept, onUpdateBriefMainField]);

  useEffect(() => navigate(`/${BRIEF}?${urlParams}`, { replace: true }), [navigate, urlParams]);

  // using small initial delay to fetch a brief from BE
  useEffect(() => {
    setTimeout(() => setInitialLoading(false), 300);
  }, []);

  const value = {
    initialLoading,
    loading: briefLoading || createBriefLoading || updateBriefLoading || initialLoading,
    error: !!briefError,

    initialBrief,
    brief,

    client,
    adConcept,

    isBriefChanged,
    isReadOnlyMode,

    onUpdateBriefMainField,
    onUpdateBriefConceptField,
    onUpdateBriefDeliverablesField,
    onUpdateBriefUgcField,
    onUpdateBriefCreatorsField,
    onUpdateBriefCopyField,
    onUpdateBriefCreativeField,

    onSaveBrief,
    onCancel,
  } as BriefContextProps;

  return <BriefContext.Provider value={value}>{children}</BriefContext.Provider>;
};

export default BriefProvider;
