/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { AD_DNA_COLUMNS } from 'components/features/private/adsDna/adsDnaUtils';
import { Button, Checkbox } from 'components/common';
import { getFullName } from 'utils/userUtils';
import { getContactUsMessage, getLocalisedLanguage, getLocalisedSocialMediaChannel } from 'utils/i18nUtils';
import DropDownInput from 'components/common/dropdownInput';
import { Client, LanguageCode, SecurityRole, SocialMediaChannel, User } from 'types/types.d';
import { AdDna, AdDnaLayout, AdDnaRoleType, AdDnaSingleAssetFormatType } from 'types/businessTypes.d';
import { ReactComponent as VerticalLayoutIcon } from 'assets/img/verticalLayout.svg';
import { ReactComponent as HorizontalLayoutIcon } from 'assets/img/horizontalLayout.svg';
import { ReactComponent as SquareLayoutIcon } from 'assets/img/squareLayout.svg';
import VisualFeature from 'components/features/private/adsDna/adDnaDetails/VisualFeature';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import cloneDeep from 'lodash/cloneDeep';
import CtaPlacement from 'components/features/private/adsDna/adDnaDetails/CtaPlacement';
import { AdDnaUpdateRequest } from 'types/inputTypes';
import { Variant } from 'components/common/dropdownMenu/types';
import clsx from 'classnames';
import { useMutation } from '@apollo/client';
import { updateAdDnas } from 'graphql/businessMutations';
import logger from 'utils/logger/logger';
import { toast } from 'react-toastify';
import ToasterInfo from 'components/common/toasterInfo';
import { baseErrorNotification, baseSuccessNotification } from 'utils/notificationUtils';
import { black_0, black_100 } from 'utils/styleUtils';
import { CloseOutlined } from '@ant-design/icons';

import styles from './MultiEditModal.module.scss';
import MultiSelectDropDown from 'components/common/multiSelectDropdown';

interface Props {
  ids: string[];
  clients: Client[];
  selectedClientId: string;
  users: User[];
  onHide: () => void;
  onDone: () => void;
}

export default function MultiEditModal({ ids, clients, selectedClientId, users, onHide, onDone }: Props): JSX.Element {
  const { t } = useTranslation();

  const [doUpdateAdDnas] = useMutation<{ updateAdDnas: AdDna[] }>(updateAdDnas);

  const [field, setField] = useState<AD_DNA_COLUMNS>(AD_DNA_COLUMNS.CHANNEL);
  const [updateRequest, setUpdateRequest] = useState<AdDnaUpdateRequest>({});

  const clientsById = useMemo(() => new Map(clients.map((c) => [c.id, c])), [clients]);
  const client = useMemo(() => clientsById.get(selectedClientId || ''), [clientsById, selectedClientId]);

  const creatorAndPgUsers = useMemo(
    () => [
      { id: '', name: '' } as Variant,
      ...users
        .filter(({ role }) => [SecurityRole.CREATOR, SecurityRole.PG_USER].includes(role))
        .map(
          (i) =>
            ({
              id: i.id,
              name: getFullName(i),
            } as Variant),
        ),
    ],
    [users],
  );

  const sections = useMemo(
    () => [
      {
        section: t('PRIVATE.USER.ADS_DNA.MAIN'),
        items: [AD_DNA_COLUMNS.CHANNEL],
      },
      {
        section: t('PRIVATE.USER.ADS_DNA.AD_CONCEPT'),
        items: [
          AD_DNA_COLUMNS.PRODUCT_FEATURES,
          AD_DNA_COLUMNS.VALUE_PROPOSITIONS,
          AD_DNA_COLUMNS.LAYOUT,
          AD_DNA_COLUMNS.ASSET_FORMAT_TYPE,
          AD_DNA_COLUMNS.ASSET_TYPE,
          // AD_DNA_COLUMNS.ASSET_SUB_TYPE,
        ],
      },
      {
        section: t('PRIVATE.USER.ADS_DNA.ROLES'),
        items: [
          AD_DNA_COLUMNS.ACCOUNT_MANAGER,
          AD_DNA_COLUMNS.CREATIVE_STRATEGIST,
          AD_DNA_COLUMNS.COPYWRITER,
          AD_DNA_COLUMNS.GRAPHIC_DESIGNER,
          AD_DNA_COLUMNS.MOTION_DESIGNER,
          AD_DNA_COLUMNS.ILLUSTRATOR,
          AD_DNA_COLUMNS.MODEL,
          AD_DNA_COLUMNS.PHOTOGRAPHER,
          AD_DNA_COLUMNS.VIDEOGRAPHER,
          AD_DNA_COLUMNS.CREATOR,
        ],
      },
    ],
    [t],
  );

  const showSuccessNotification = useCallback((title: string) => {
    toast.success(<ToasterInfo type="success" title={title} />, { ...baseSuccessNotification });
  }, []);

  const showErrorNotification = useCallback(() => {
    const message: string = t(getContactUsMessage());
    toast.error(<ToasterInfo type="error" description={message} />, { ...baseErrorNotification });
  }, [t]);

  const handleSubmit = useCallback((): void => {
    if (JSON.stringify(updateRequest).toString() === '{}') {
      onHide();
      return;
    }

    doUpdateAdDnas({ variables: { ids, request: updateRequest } })
      .then((result) => {
        if (result?.data?.updateAdDnas?.length) {
          showSuccessNotification(t('PRIVATE.USER.ADS_DNA.MULTI_EDIT.SUCCESS'));
          onDone();
        } else {
          logger.error(`Error updating ad dnas`);
          showErrorNotification();
        }
      })
      .catch((e) => {
        logger.error(`Error updating ad dnas: ${e}`);
        showErrorNotification();
      });
  }, [doUpdateAdDnas, ids, onDone, onHide, showErrorNotification, showSuccessNotification, t, updateRequest]);

  return (
    <Modal
      open={true}
      onCancel={onHide}
      className={styles.modal}
      footer={null}
      maskClosable={false}
      width={900}
      closeIcon={
        <CloseOutlined
          rev={undefined}
          style={{
            color: black_0,
            transition: 'color 0.3s',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.color = black_100)}
          onMouseLeave={(e) => (e.currentTarget.style.color = black_0)}
        />
      }
    >
      <div className={styles.modalHeader}>
        <div className={styles.title}>
          {ids.length > 1
            ? t('PRIVATE.USER.ADS_DNA.MULTI_EDIT.TITLE_MULTIPLE', { count: ids.length })
            : t('PRIVATE.USER.ADS_DNA.MULTI_EDIT.TITLE')}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.main}>
          <div className={styles.left}>
            {sections.map(({ section, items }) => (
              <div key={section} className={styles.section}>
                <div className={styles.sectionHeader}>{section}</div>
                {items.map((i) => (
                  <div
                    key={i}
                    className={clsx(styles.sectionItem, { [styles.sectionItemActive]: i === field })}
                    onClick={() => {
                      if (i !== field) setField(i);
                    }}
                  >
                    <div>{t(`PRIVATE.USER.ADS_DNA.${i}`)}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className={styles.right}>
            {/************ MAIN ************/}

            {field === AD_DNA_COLUMNS.CHANNEL && (
              <div className={styles.item}>
                <div className={styles.itemInput}>
                  <DropDownInput
                    className={styles.dropdown}
                    initialValue={{
                      name: !!updateRequest.channel?.length
                        ? getLocalisedSocialMediaChannel(updateRequest.channel, t)
                        : '',
                      selected: true,
                      id: updateRequest.channel || '',
                    }}
                    takeValue={(arg: string, id) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setUpdateRequest((prev) => ({ ...prev, channel: id?.length ? id : null }));
                    }}
                    variants={[
                      '',
                      SocialMediaChannel.TIK_TOK,
                      SocialMediaChannel.FACEBOOK,
                      SocialMediaChannel.GOOGLE,
                      SocialMediaChannel.SNAPCHAT,
                      SocialMediaChannel.PINTEREST,
                    ].map((i) => ({
                      name: !!i?.length ? getLocalisedSocialMediaChannel(i as SocialMediaChannel, t) : '',
                      selected: (updateRequest.channel || '') === i,
                      id: i,
                    }))}
                  />
                </div>
              </div>
            )}

            {/************ AD CONCEPT ************/}

            {field === AD_DNA_COLUMNS.PAIN_POINTS && (
              <div className={styles.item}>
                <MultiSelectDropDown
                  takeValues={(values) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setUpdateRequest((prev) => ({ ...prev, painPoints: values.map(({ id }) => id) }));
                  }}
                  variants={
                    client?.painPoints?.map((i) => ({
                      id: i,
                      name: i,
                      selected: updateRequest.painPoints?.includes(i),
                    })) || []
                  }
                />
              </div>
            )}
            {field === AD_DNA_COLUMNS.PRODUCT_FEATURES && (
              <div className={styles.item}>
                <MultiSelectDropDown
                  takeValues={(values) => {
                    // @ts-ignore
                    setUpdateRequest((prev) => ({
                      ...prev,
                      // @ts-ignore
                      productFeatures: values.map(({ id }) => id),
                    }));
                  }}
                  variants={
                    client?.productFeatures?.map((i) => ({
                      id: i,
                      name: i,
                      selected: updateRequest.productFeatures?.includes(i),
                    })) || []
                  }
                />
              </div>
            )}
            {field === AD_DNA_COLUMNS.VALUE_PROPOSITIONS && (
              <div className={styles.item}>
                <MultiSelectDropDown
                  takeValues={(values) => {
                    setUpdateRequest((prev) => ({
                      ...prev,
                      // @ts-ignore
                      valuePropositions: values.map(({ id }) => id),
                    }));
                  }}
                  variants={
                    client?.valuePropositions?.map((i) => ({
                      id: i,
                      name: i,
                      selected: updateRequest.valuePropositions?.includes(i),
                    })) || []
                  }
                />
              </div>
            )}
            {field === AD_DNA_COLUMNS.LAYOUT && (
              <div className={styles.item}>
                <div className={styles.itemInput}>
                  <div className={styles.visualFeatures}>
                    {[
                      {
                        feature: AdDnaLayout.VERTICAL,
                        text: t('LAYOUT.VERTICAL'),
                        icon: <VerticalLayoutIcon />,
                      },
                      {
                        feature: AdDnaLayout.HORIZONTAL,
                        text: t('LAYOUT.HORIZONTAL'),
                        icon: <HorizontalLayoutIcon />,
                      },
                      {
                        feature: AdDnaLayout.SQUARE,
                        text: t('LAYOUT.SQUARE'),
                        icon: <SquareLayoutIcon />,
                      },
                    ].map(({ feature, text, icon }) => (
                      <VisualFeature
                        key={feature}
                        active={updateRequest.layout === feature}
                        icon={icon}
                        text={text}
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          setUpdateRequest((prev) => ({ ...prev, layout: feature }));
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {field === AD_DNA_COLUMNS.ASSET_FORMAT_TYPE && (
              <div className={styles.item}>
                <div className={styles.itemInput}>
                  <DropDownInput
                    className={styles.dropdown}
                    initialValue={
                      updateRequest.singleAssetFormatType
                        ? {
                            name: !!updateRequest.singleAssetFormatType?.length
                              ? capitalizeFirstLetter(
                                  updateRequest.singleAssetFormatType.toLowerCase().replace('_', ' '),
                                ) || ''
                              : '',
                            selected: false,
                            id: updateRequest.singleAssetFormatType,
                          }
                        : undefined
                    }
                    takeValue={(arg: string, id) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setUpdateRequest((prev) => ({
                        ...prev,
                        singleAssetFormatType: id === '' ? null : id,
                      }));
                    }}
                    variants={['', AdDnaSingleAssetFormatType.STATIC, AdDnaSingleAssetFormatType.MOTION].map((i) => ({
                      name: i === '' ? '' : capitalizeFirstLetter(i.toLowerCase().replace('_', ' ')) || '',
                      selected: updateRequest.singleAssetFormatType === i,
                      id: i,
                    }))}
                  />
                </div>
              </div>
            )}
            {/*{field === AD_DNA_COLUMNS.ASSET_TYPE && (*/}
            {/*  <div className={styles.item}>*/}
            {/*    <div className={styles.itemInput}>*/}
            {/*      <DropDownInput*/}
            {/*        className={styles.dropdown}*/}
            {/*        initialValue={*/}
            {/*          updateRequest.singleAssetType*/}
            {/*            ? {*/}
            {/*                name: updateRequest.singleAssetType*/}
            {/*                  ? capitalizeFirstLetter(updateRequest.singleAssetType.toLowerCase().replace('_', ' ')) ||*/}
            {/*                    ''*/}
            {/*                  : '',*/}
            {/*                selected: false,*/}
            {/*                id: updateRequest.singleAssetType,*/}
            {/*              }*/}
            {/*            : undefined*/}
            {/*        }*/}
            {/*        takeValue={(arg: string, id) => {*/}
            {/*          // eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*          setUpdateRequest((prev) => ({*/}
            {/*            ...prev,*/}
            {/*            singleAssetType: id === AdDnaSingleAssetType.NONE ? null : id,*/}
            {/*          }));*/}
            {/*        }}*/}
            {/*        variants={(updateRequest.singleAssetFormatType === AdDnaSingleAssetFormatType.STATIC*/}
            {/*          ? [*/}
            {/*              AdDnaSingleAssetType.NONE,*/}
            {/*              AdDnaSingleAssetType.CP,*/}
            {/*              AdDnaSingleAssetType.GRAPHIC_DESIGN,*/}
            {/*              AdDnaSingleAssetType.ILLUSTRATION,*/}
            {/*              AdDnaSingleAssetType.PHOTOGRAPHY,*/}
            {/*            ]*/}
            {/*          : [AdDnaSingleAssetType.MOTION, AdDnaSingleAssetType.LIVE_ACTION]*/}
            {/*        ).map((i) => ({*/}
            {/*          name:*/}
            {/*            i === AdDnaSingleAssetType.NONE*/}
            {/*              ? ''*/}
            {/*              : capitalizeFirstLetter(i.toLowerCase().replace('_', ' ')) || '',*/}
            {/*          selected: updateRequest.singleAssetType === i,*/}
            {/*          id: i,*/}
            {/*        }))}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            {/*{field === AD_DNA_COLUMNS.ASSET_SUB_TYPE && (*/}
            {/*  <div className={styles.item}>*/}
            {/*    <div className={styles.itemInput}>*/}
            {/*      <DropdownMultiInput*/}
            {/*        className={styles.dropdown}*/}
            {/*        initialValue={*/}
            {/*          updateRequest.singleAssetSubType*/}
            {/*            ? {*/}
            {/*                name: capitalizeFirstLetter(updateRequest.singleAssetSubType.toLowerCase().replace('_', ' ')) || '',*/}
            {/*                selected: false,*/}
            {/*                id: updateRequest.singleAssetSubType,*/}
            {/*              }*/}
            {/*            : undefined*/}
            {/*        }*/}
            {/*        takeValue={(arg: string, id) => {*/}
            {/*          // eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/*          setUpdateRequest((prev) => ({*/}
            {/*            ...prev,*/}
            {/*            singleAssetSubType: id === AdDnaSingleAssetSubType.NONE ? null : id,*/}
            {/*          }));*/}
            {/*        }}*/}
            {/*        variants={getAssetSubType(updateRequest.singleAssetType || null).map((i) => ({*/}
            {/*          name:*/}
            {/*            i === AdDnaSingleAssetSubType.NONE*/}
            {/*              ? ''*/}
            {/*              : capitalizeFirstLetter(i.toLowerCase().replace('_', ' ')) || '',*/}
            {/*          selected: updateRequest.singleAssetSubType === i,*/}
            {/*          id: i,*/}
            {/*        }))}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}

            {/************ ROLES ************/}

            {[
              { column: AD_DNA_COLUMNS.ACCOUNT_MANAGER, role: AdDnaRoleType.ACCOUNT_MANAGER },
              { column: AD_DNA_COLUMNS.CREATIVE_STRATEGIST, role: AdDnaRoleType.CREATIVE_STRATEGIST },
              { column: AD_DNA_COLUMNS.COPYWRITER, role: AdDnaRoleType.COPYWRITER },
              { column: AD_DNA_COLUMNS.GRAPHIC_DESIGNER, role: AdDnaRoleType.GRAPHIC_DESIGNER },
              { column: AD_DNA_COLUMNS.MOTION_DESIGNER, role: AdDnaRoleType.MOTION_DESIGNER },
              { column: AD_DNA_COLUMNS.ILLUSTRATOR, role: AdDnaRoleType.ILLUSTRATOR },
              { column: AD_DNA_COLUMNS.MODEL, role: AdDnaRoleType.MODEL },
              { column: AD_DNA_COLUMNS.PHOTOGRAPHER, role: AdDnaRoleType.PHOTOGRAPHER },
              { column: AD_DNA_COLUMNS.VIDEOGRAPHER, role: AdDnaRoleType.VIDEOGRAPHER },
              { column: AD_DNA_COLUMNS.CREATOR, role: AdDnaRoleType.CREATOR },
            ].map(({ column, role }) =>
              field === column ? (
                <div key={role} className={styles.item}>
                  <div className={styles.itemInput}>
                    <DropDownInput
                      className={styles.dropdown}
                      initialValue={creatorAndPgUsers.find(({ id }) =>
                        updateRequest.roles?.find((i) => i.role === role && i.userId === id),
                      )}
                      takeValue={(arg: string, id) => {
                        let newArray = cloneDeep(updateRequest?.roles || []);
                        if (newArray.find((j) => j.role === role)) {
                          newArray = newArray.filter((j) => j.role !== role);
                        }
                        if (id !== '') newArray.push({ role, userId: id });
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setUpdateRequest((prev) => ({ ...prev, roles: newArray }));
                      }}
                      variants={creatorAndPgUsers}
                      menuContainerClassname={styles.dropdownInputWrapper}
                    />
                  </div>
                </div>
              ) : (
                <></>
              ),
            )}

            {/************ SCRIP/COPY ************/}

            {field === AD_DNA_COLUMNS.LANGUAGE && (
              <div className={styles.item}>
                <div className={styles.itemInput}>
                  {[LanguageCode.EN, LanguageCode.DE, LanguageCode.SE, LanguageCode.FI, LanguageCode.RU].map((i) => (
                    <Checkbox
                      className={styles.checkbox}
                      key={i}
                      label={getLocalisedLanguage(i, t)}
                      labelAfter={true}
                      checked={(updateRequest.languages || []).includes(i.toString())}
                      onChange={(e) => {
                        const checked = e.target?.checked || false;
                        let newArray = cloneDeep(updateRequest.languages || []);
                        if (checked && !newArray.includes(i)) newArray.push(i);
                        else newArray = newArray.filter((j) => j !== i.toString());

                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setUpdateRequest((prev) => ({ ...prev, languages: newArray }));
                      }}
                    />
                  ))}
                </div>
              </div>
            )}

            {/************ METHODOLOGY ************/}

            {field === AD_DNA_COLUMNS.CTA_PLACEMENT && (
              <div className={styles.ctaPlacementItem}>
                <CtaPlacement
                  value={updateRequest.ctaPlacement || null}
                  onClick={(value) => {
                    // @ts-ignore
                    setUpdateRequest((prev) => ({ ...prev, ctaPlacement: value }));
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles.buttons}>
          <Button buttonType="secondary" onClick={onHide}>
            {t('COMMON.CANCEL')}
          </Button>
          <Button buttonType="primary" type="button" onClick={handleSubmit}>
            {t('PRIVATE.USER.ADS_DNA.CUSTOMIZE_COLUMNS.CTA')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
